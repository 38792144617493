<template>
  <a-table
    :columns="computedColumns"
    :data-source="data"
    :rowKey="rowKey"
    :rowClassName="getRowClassName"
    @change="tableChanged"
    :size="size">
  </a-table>
</template>
<script>
import { genericCompare } from '@/utils/sort.js'

export default {
  props: {
    rowKey: { type: String, default: () => '' },
    size: { type: String, default: () => 'default' },
    columns: { type: Array, default: () => [] },
    data: { type: Array, default: () => [] },
    actions: { type: Array, default: () => [] },
    pageChanged: { type: Function, default: () => function () {} }
  },
  computed: {
    computedColumns: function () {
      var array = []
      for (var i = 0; i < this.columns.length; i++) {
        array[i] = this.columns[i]
        if (array[i].sorter === undefined && array[i].dataIndex) {
          array[i].sorter = this.defaultCompare(array[i].dataIndex)
        }
      }
      return array
    }
  },
  data () {
    return {
      currentPage: 1
    }
  },
  methods: {
    toString (value) {
      value = value || ''
      return value.toString()
    },
    defaultCompare (key) {
      var that = this
      return function (a, b) {
        return genericCompare(that.toString(a[key]), that.toString(b[key]))
      }
    },
    getRowClassName (record, index) {
      if (index % 2 === 0) {
        return 'light-row'
      }
      return 'dark-row'
    },
    tableChanged (pagination, filters, sorter, { currentDataSource }) {
      if (pagination.current !== this.currentPage) {
        this.currentPage = pagination.current
        var fn = (this.pageChanged || this.emptyFunc)
        fn(this.currentPage, pagination.pageSize)
      }
    },
    emptyFunc () {
      return true
    }
  }
}
</script>

<style scoped>
/deep/ .ant-table-thead {
  background-color: #f9f9f9;
}

/deep/ .ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0;
}

/deep/ .light-row {
  background-color: #fff;
}

/deep/ .dark-row {
  background-color: #f9f9f9;
}
</style>
